@import './variables.scss';

:root {
  --myColor: black;
}

* {
  font-family: $font-family;
}

.wf-loading:not(.wf-inactive),
.wf-loading:not(.wf-inactive) * {
  color: transparent !important;
}

*::before,
*::after {
  box-sizing: border-box;
}

::selection {
  color: white;
  background-color: $primary-color;
}

html {
  box-sizing: border-box;
  border: 0;
  margin: 0;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
  }

  h1 {
    font-size: $heading-1;
    margin: 0.83em 0;
  }
  h2 {
    font-size: $heading-2;
    margin: 0.83em 0;
  }
  h3 {
    font-size: $heading-3;
    margin: 1em 0;
  }
  h4 {
    font-size: $heading-4;
    margin: 1.33em 0;
  }
  h5 {
    font-size: $heading-5;
  }
  h6 {
    font-size: $heading-6;
  }

  @media (max-width: 600px) {
    font-size: $font-size-small;

    h1 {
      font-size: $heading-2;
    }
    h2 {
      font-size: $heading-3;
    }
    h3 {
      font-size: $heading-4;
    }
    h4 {
      font-size: $heading-5;
    }
    h5 {
      font-size: $heading-6;
    }
    h6 {
      font-size: $heading-6;
    }
  }
}

body {
  border: 0;
  margin: 0;
  padding: 0;
  color: $dark-color;
  font-family: $font-family;
  background: white;
  font-size: $font-size;
}

a {
  transition: all 0.2s ease-in-out;
  color: $title-color;
  text-decoration: underline;
  &:hover,
  &:focus {
    color: $title-color-hover;
  }

  p {
    color: $text-color;
  }
}
