@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';

.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include screen(max-sm) {
    margin-bottom: 4vh;
  }
}

.radioGroup {
  display: flex;
  justify-content: flex-start;
  margin-top: 18px;
}

.radioLabel {
  text-align: left;
  color: $grey-color;
  font-size: $font-size-small;
  font-family: $font-family;
  margin: 0 !important;
}
