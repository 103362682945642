@import '../../../../../assets/styles/partials/variables.scss';
@import '../../../../../assets/styles/partials/mixins.scss';

.card {
  background: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  min-width: 142px;
  height: 78px;
  @include screen(xs) {
    width: 100%;
    flex-direction: row;
    align-items: center;
  }
}

.whiteInput {
  background: #fff;
}

.greyInput {
  background: #f5f5f5;
}

.yourProposalSection {
  & > .inputValues {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    button {
      margin-top: 26px;
      width: 190px;
    }

    @include screen(max-sm) {
      flex-direction: column;
      align-items: center;

      button {
        margin-top: 8px;
        width: 100%;
      }
    }
  }

  & > .summaryValues {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 14px;

    span {
      color: #000;
      font-weight: bold;
    }

    @include screen(max-sm) {
      flex-direction: column;
      align-items: center;
    }
  }
}
