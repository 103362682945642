@import 'src/assets/styles/partials/variables.scss';
@import 'src/assets/styles/partials/mixins.scss';

.inputContainer {
  display: flex;
  flex-direction: column;
  position: relative;

  .capitalized {
    text-transform: capitalize;
  }

  .invalid {
    border: 1.2px solid $error !important;
  }

  .valid {
    border: 1.2px solid $aceppted !important;
  }

  .icon {
    position: absolute;
    right: 0px;
    top: 23px;
  }

  .inputElement {
    display: flex;
    align-items: center;
    position: relative;
    border: 1.2px solid $grey-color;
    border-radius: 5px;
    margin-top: 5px;
    padding-bottom: 5px;

    svg {
      position: absolute;
      right: 5px;
    }

    input,
    textarea {
      width: 92%;
      border-style: none;
      outline: 0;
      height: 20px;
      font-size: $font-size-small;
      font-family: $font-family;
      background: inherit;
      @include screen(sm) {
        &[type='date'] {
          font-size: calc(8px + 0.6vw);
        }
      }
    }

    textarea {
      height: 100%;
      width: 100%;
    }
  }

  p {
    text-align: left;
    color: $grey-color;
    font-size: $font-size-small;
    font-family: $font-family;
    margin: 0 !important;
    padding-left: 2px;
  }

  .errorMessage {
    border-radius: 5px;
    line-height: 1.2em;
    padding: 10px;
    p {
      color: red;
      font-size: 70%;
    }
  }
}

@include screen(xs) {
  .max,
  .xlarge,
  .large,
  .medium,
  .small,
  .xsmall {
    width: 100%;
  }
}

@include screen(sm) {
  .max,
  .xlarge,
  .large {
    width: 100%;
  }
  .medium,
  .small,
  .xsmall {
    width: 45%;
  }
}

@include screen(min-md) {
  .xsmall {
    width: 15.1%;
  }

  .small {
    width: 32%;
  }

  .medium {
    width: 49%;
  }
  .xmedium {
    width: 302px;
  }

  .large {
    width: 65.8%;
  }

  .xlarge {
    width: 75%;
  }

  .max {
    width: 100%;
  }
}
