@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';

.modalContainer {
  width: 520px;

  @include screen(xs) {
    width: 80vw;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    img {
      width: 46px;
    }

    h1 {
      font-size: $font-size-hg;
      color: $title-color;
    }

    p {
      font-size: $font-size-med;
    }

    button {
      margin-top: 28px;
      width: 206px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
