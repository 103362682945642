@import '../../../../../assets/styles/partials/variables.scss';
@import '../../../../../assets/styles/partials/mixins.scss';

.card {
  background: #fff;
  border-radius: 8px;
  padding: 12px;
  text-align: center;
}

.separator {
  height: 80%;
  min-height: 24px;
  width: 1px;
  background: $grey-color;
}

.propertyValuesSection {
  > .propertyValues {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 18px 0;
  }

  .ratedValue span {
    text-decoration: line-through;
  }

  .saleValue span {
    color: $title-color;
    font-weight: bold;
    font-size: 18px;
  }

  @include screen(max-sm) {
    .ratedValue,
    .saleValue {
      display: flex;
      flex-direction: column;
    }
  }
}
