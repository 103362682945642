.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.base {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.wrapper {
  z-index: 1;
  position: relative;
  opacity: 0;
  transition: opacity 0.5s;
}

.wrapper.active {
  opacity: 1;
}

.wrapper.leaving {
  animation: leaving 0.5s;
}

.wrapper.hidden {
  position: absolute;
  left: -100vw;
}

@keyframes leaving {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
