@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';

.container {
  width: 260px;
  height: 247px;
  border: none;
  background: $white-color;
  border-radius: 4px;
  border: 1px solid $light-grey;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px;
  box-sizing: border-box;

  @include screen(max-md) {
    width: 90%;
    margin: 0 0 24px;
  }

  &-finished {
    border: 1px solid $sea-green;
  }

  &-reproved {
    border: 1px solid $error;
  }
  &-incomplete {
    border: 1px solid inherit;
  }

  & > div {
    width: 100%;
  }

  h1 {
    font-size: $font-size;
    color: $black-color;
    margin: 0;
    border-bottom: 1px solid $line-color;
    width: 100%;
    padding-bottom: 5px;
    margin-bottom: 15px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .options {
      width: 20px;
      height: 20px;
    }

    .listOptions {
      height: auto;
      min-width: 145px;
      position: absolute;
      z-index: 50;
      background-color: #ffffff;
      border: 1px solid #ececec;
      box-sizing: border-box;
      border-radius: 6px;
      padding: 6px 12px;
      right: 6px;
      top: 24px;

      section {
        cursor: pointer;

        li {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 22px;
          color: #444444;

          &:hover {
            transition: all 0.2s ease-in-out;
            transform: scale(1.03);
          }
        }

        hr {
          margin: 4px 0 4px 0;
        }

        &:last-child {
          hr {
            display: none;
          }
        }
      }
    }
  }

  .status {
    display: flex;
    align-items: flex-start;

    p {
      font-size: $font-size-small;
      color: $text-color;
      margin-left: 8px;
    }
  }

  .link {
    background: transparent;
    text-decoration: underline;
    border: none;
    padding: 0;
    margin-top: 10px;
    cursor: pointer;
  }

  .primary-button {
    margin-top: 12px;
    width: 100%;
  }
}
