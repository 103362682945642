@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';

.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: hsla(0, 0%, 0%, 0.8);
  z-index: 3;
}

.sideMenu {
  background-color: $light-dark-color;
  width: 100%;
  max-width: 224px;
  height: auto;
  min-height: 100vh;

  @include screen(xs) {
    height: 100vh;
  }

  li {
    display: flex;
    border-top: 0.5px solid hsla(0, 0%, 89%, 1);
    border-bottom: 0.5px solid hsla(0, 0%, 89%, 1);

    &:first-child {
      margin-top: 36px;
    }
  }

  a {
    padding: 10px 0;
    width: 100%;
    height: 100%;
    padding-left: 25px;
    text-decoration: none;
    color: $grey-text;
  }

  li:hover a {
    cursor: pointer;
    background-color: $light-grey;
    color: $secondary-color;
    font-weight: 600;
    transition: color font-weight 0.4s;
  }

  .select {
    cursor: pointer;
    background-color: $light-grey;
    border-left: 5px;
    border-color: $green;
    border-style: solid;
    color: $secondary-color;
    font-weight: 600;
  }
}

.mobile {
  width: 100%;
  position: absolute;
  z-index: 2;
}
