.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.value {
  font-size: 1.05em;
}

.strike {
  text-decoration: line-through;
}
