@import '../../assets/styles/partials/variables.scss';

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 0;
  cursor: pointer;

  > p {
    margin-top: -3.5px;
    margin-left: 16px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #444444;

    span {
      font-weight: bold;
    }
  }

  > .toggle {
    width: 30px;
    height: 20px;
  }
}
