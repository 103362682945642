@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';

.container {
  display: flex;

  @include screen(max-sm) {
    flex-direction: column;
  }
}
