@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';

.modalContainer {
  @include screen(xs) {
    width: 80vw;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    img {
      height: 46px;
      margin-bottom: 12px;
    }

    h1 {
      font-size: $font-size-hg;
      color: $title-color;
      margin: 0;
      margin-bottom: 8px;
    }

    p {
      font-size: $font-size-small;
    }

    button {
      margin-top: 20px;
    }

    button + button {
      margin-top: 14px;
    }
  }
}
