@import '../../assets/styles/partials/mixins.scss';
@import '../../assets/styles/partials/variables.scss';

.container {
  position: relative;
  @media (max-width: 4000px) and (min-width: 300px) {
    ul {
      width: auto !important;
      padding: 0 !important;
      bottom: 1%;
      li {
        margin: 0 !important;
      }
    }
  }

  ul[class*='react-multi-carousel-dot-list '] {
    display: none;
  }

  > button {
    margin: 0 -1% !important;
    color: #fff9f9 !important;
    margin: 0 auto;
    transition: all 0.3s ease 0s;
    top: 54%;
    background-color: #f9f9f9 !important;
    min-width: 24px;
    min-height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 900;

    &::before {
      color: #333;
      font-size: 14px;
      font-weight: 600;
    }
    &:hover {
      color: #111111 !important;
    }
  }
}
