@import '../../assets/styles/partials/variables.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 88px;
  border: none;
  background: $light-dark-color;
  border-radius: 4px;
  cursor: pointer;
  box-sizing: border-box;

  h1 {
    margin: 10px 0;
    font-size: $font-size;
    font-weight: normal;
    color: $text-color;
  }
}
.container:hover {
  transform: scale(1.005);
}
.icon {
  background: $white-color;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 12px;
}
