@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';

.overlay {
  position: fixed;
  display: block;
  overflow: auto;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  cursor: pointer;

  & > * {
    cursor: initial;
  }
}

.normal {
  margin: 10% auto;
  width: 50vw;
  padding: 2rem;
  position: relative;
  border-radius: 5px;
  background-color: $white-color;
}

.fullscreen {
  width: 100%;
  height: 100vh;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $background-color;
}

.small {
  max-width: 500px;
  padding: 2rem;
  position: relative;
  max-height: 85vh;
  overflow-y: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  background-color: $white-color;
}

.xsmall {
  max-width: 500px;
  padding: 1rem;
  position: relative;
  max-height: 80vh;
  overflow-y: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  background-color: $white-color;

  @include screen(xs) {
    max-width: 85vw;
  }
}

.closeButton {
  position: absolute;
  top: 0.3rem;
  right: 0.5rem;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}
