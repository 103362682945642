@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';

.page {
  padding: 35px;
  width: 90vw;

  @include screen(xs) {
    padding: 24px 16px;
  }

  .link {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #000000;

    span {
      cursor: pointer;
    }
  }
  .container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 16px;
    margin-top: 24px;

    @include screen(xs) {
      flex-direction: column;
    }

    section {
      width: 70%;

      @include screen(xs) {
        order: 2;
        width: 100%;
      }

      .background {
        background: $light-dark-color;
        border-radius: 12px;
        padding: 32px 28px;
      }

      h2 {
        font-style: normal;
        font-size: 14px;
        color: $text-color;
        font-weight: bold;
        margin-top: 16px;
      }
      .actions {
        display: flex;
        justify-content: flex-end;
        margin-top: 24px;

        @include screen(xs) {
          flex-direction: column;
          align-items: center;
        }

        button {
          margin-left: 16px;

          @include screen(xs) {
            margin: 6px 0;
            width: 80%;
          }
        }
      }
    }
  }
}
