@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';

.container {
  display: flex;
  width: 100%;
  height: 88px;
  border: none;
  background: $light-dark-color;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 22px 0 22px 36px;

  @include screen(xs) {
    height: 110px;
  }

  .texts {
    font-family: Open Sans;
    font-style: normal;
    @include screen(xs) {
      white-space: pre;
      width: 100px;
    }
  }

  h1 {
    font-weight: bold;
    font-size: $font-size;
    line-height: 0px;
    color: $black-color;
  }

  p {
    font-weight: normal;
    font-size: $font-size-small;
    color: $text-color;
  }
}
.container:hover {
  transform: scale(1.003);
}
.icon {
  margin-right: 24px;
}
