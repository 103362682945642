@import '../../assets/styles/partials/variables.scss';

.tag {
  text-align: center;
  white-space: nowrap;
  padding: 4px 12px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 50px;
  color: $black-light;
  font-size: $font-size-small;
  border: 1px solid $border-grey;
  background-color: $border-grey;
}
