@import '../../../../assets/styles/partials/variables.scss';
@import '../../../../assets/styles/partials/mixins.scss';

.documentationContainer {
  max-width: 858px;
  min-height: 617px;
  background: $background-color;
  color: $brow-grey;
  padding: 16px 0 16px 24px;

  display: flex;
  flex-direction: column;

  .proponentDocumentContainer {
    display: flex;
    flex-wrap: wrap;
  }

  header {
    color: $title-color;
    font-size: 20px;

    display: flex;
    align-items: center;
    @include screen(max-md) {
      width: 343px;
    }
  }

  header img {
    width: 100%;
    height: 100%;
    margin-right: 10px;
    max-width: 40px;
    max-height: 40px;
  }
  header h1 {
    margin: 0;
  }

  & > p {
    margin-bottom: 24px;
    @include screen(max-md) {
      width: 343px;
    }
  }
}
.proponentList {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.card {
  width: 100%;
  max-width: 368px;
}
