@import '../../../assets/styles/partials/variables.scss';
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dots {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.dot {
  width: 6px;
  height: 6px;
  background: #d8d8d8;
  border-radius: 50%;
  margin: 10px 5px 12px;
}

.dot.active {
  background: $sea-green;
}

.buttonWrapper {
  display: flex;
  width: 80%;
  justify-content: center;
  :nth-child(2) {
    margin-left: 15px;
  }
}
