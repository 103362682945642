@import '../../assets/styles/partials/variables.scss';

.container {
  width: 258px;
  height: 229px;
  border: none;
  background: $white-color;
  border-radius: 4px;
  border: 1px solid $light-grey;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px;
  box-sizing: border-box;

  h1 {
    font-size: $font-size;
    color: $black-color;
    margin: 0;
  }

  p {
    font-size: $font-size-small;
    color: $text-color;
  }

  p.empty {
    color: $grey-color;
  }

  p:nth-child(3) {
    flex: 0.5;
  }

  .button-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
  }

  .delete {
    border: none;
    background: transparent;
    padding: 4px 0 0;
    margin: 0;
    cursor: pointer;
  }

  .delete:hover {
    transition: all 0.2s ease-in-out;
    transform: scale(1.15);
    color: $error;
  }

  button:last-child {
    align-self: flex-end;
    font-size: $font-size-xsmall;
  }
}
