@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';

.container {
  width: 100%;

  h1 {
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: $title-color;
    margin-bottom: 53px;
    margin-top: 48px;
  }

  .bottom {
    margin-top: 60px;

    & > div {
      z-index: 10;
    }
  }
}

.button {
  display: flex;
  align-items: center;
  z-index: 10;

  button {
    border: 0;
    background-color: transparent;
    text-decoration: underline;
    cursor: pointer;
  }
}
