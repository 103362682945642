@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: start;

  @include screen(xs) {
    max-width: 343px;
  }

  > h1 {
    text-align: start;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.18px;
    color: $text-color;
  }

  .helpText {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: $text-color;
    margin-bottom: 24px;
  }

  .sectionTitle {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: $text-color;
    margin-bottom: 16px;
  }

  .options {
    box-sizing: border-box;
    background-color: $light-dark-color;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 26px;
    padding: 24px;

    .toggleSection {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}
