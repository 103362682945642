@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';

.span {
  color: $black-light;
  font-size: $font-size-hg;
  font-weight: 700;
  white-space: nowrap;

  @include screen(xs) {
    font-size: 1.7em;
  }
}
