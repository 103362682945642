@import '../../../../../assets/styles/partials/mixins.scss';

.content {
  display: flex;
  gap: 16px;
  align-items: flex-start;
  @include screen(xs) {
    flex-direction: column;
    width: 100%;
  }
}
.whiteInput {
  background: #fff;
}

.button {
  margin-top: 26px;
  @include screen(xs) {
    margin-top: 0;
    width: 100%;
  }
}
