@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-family: $font-family;
  color: $text-color;

  .welcomeTitle {
    color: $title-color;
    font-weight: 700;
    margin: 8px 79px 8px 79px;
    font-size: 23px;
    width: 432px;
    height: 27px;
  }

  .welcomeTitleMobile {
    display: none;
  }

  .welcomeContent {
    margin-left: 65px;
    margin-right: 65px;
    width: 460px;
    height: 110px;

    .firstParagraph {
      margin-bottom: 29px;
    }
  }

  .moreInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;

    img {
      height: 28px;
    }

    p {
      margin-top: 0;
    }
  }

  p {
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    margin-top: 15px;
  }

  img {
    height: 45px;
  }

  h1 {
    font-size: x-large;
  }

  a {
    font-weight: 700;
  }

  button {
    margin-top: 25px;
    max-width: 311px;
    width: 100%;

    div {
      display: inline;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.5px;
      line-height: 19px;
    }
  }
}

@media (max-width: 575px) {
  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    font-family: $font-family-open-sans;
    color: $text-color;
    border-radius: 14px;

    .welcomeTitleMobile {
      display: block;
      color: $title-color;
      font-weight: 700;
      margin: 8px 8px;
      font-size: 23px;
      width: 300px;
      height: 54px;
    }

    .welcomeTitle {
      display: none;
    }

    .welcomeContent {
      margin-left: 8px;
      margin-right: 8px;
      width: 312px;
      height: 110px;

      .firstParagraph {
        display: none;
      }
    }

    .moreInfo {
      display: none;
    }

    p {
      display: block;
      text-align: center;
      font-weight: 300;
      font-size: 14px;
      line-height: 19px;
      margin-top: 25px;
    }

    button {
      margin-top: 5px;
      max-width: 311px;
      width: 100%;

      div {
        display: inline;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.5px;
        line-height: 19px;
      }
    }
  }
}
