.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin-top: 24px;
}

@media (max-width: 755px) {
  .container {
    padding: 3%;
  }
}
