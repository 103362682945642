@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';

.container {
  width: 260px;
  height: 260px;
  border: none;
  background: $light-grey;
  border-radius: 10px;
  cursor: pointer;
  box-sizing: border-box;

  @include screen(xs) {
    width: 100%;
  }

  h1 {
    margin: 10px 0;
    font-size: $font-size-med;
    font-weight: normal;
    color: $text-color;
  }
}
.container:hover {
  transition: all 0.2s ease-in-out;
  transform: scale(1.01);
  -webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.15);
}
.icon {
  background: $white-color;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
