@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';

.container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 56px;
  background: $white-color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 45px;
  box-sizing: border-box;
  box-shadow: 0px 0px 10px 0px adjust-color($dark-grey-color, $alpha: -0.5);
  z-index: 4;
}

.button-wrapper {
  display: flex;
  align-items: center;

  & > *:first-child {
    margin: 0px 30px;
  }
}

@include screen(xs) {
  .container {
    height: auto;
    flex-direction: column-reverse;
  }

  .button-wrapper {
    flex-direction: column-reverse;
  }
}
