@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 0 26px;

  text-align: center;

  & > header {
    background: #f7f7f7;
    width: 100%;
    padding: 18px;
    margin-bottom: 22px;
  }

  & > header p {
    color: $grey-text;
  }

  & > nav {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border-bottom: 1px solid $grey-color;
    margin-bottom: 26px;
    overflow: auto;
  }

  & > nav button {
    background: none;
    border: none;
    outline: none;
    font-size: 16px;
    margin-right: 26px;
    position: relative;
    padding-bottom: 8px;
    cursor: pointer;
  }
}

.activeStep {
  font-weight: bold;

  &::before {
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: #00bfb2;
    position: absolute;
  }
}

.bottomNavigation {
  z-index: 10;
  margin-top: 60px;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: 20px;
    color: $title-color;
    margin-left: 12px;
  }
}

.none {
  display: none;
}
