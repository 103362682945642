@import '../../assets/styles/partials/variables.scss';

.container {
  width: 326px;
  height: 300px;
  border: none;
  background: #f7f7f7;
  border-radius: 10px;
  cursor: pointer;
  box-sizing: border-box;
  margin-top: 16px;
  padding: 16px;
  position: relative;

  h1 {
    margin: 10px 0;
    font-size: $font-size-med;
    font-weight: normal;
    color: $text-color;
  }
}
.container:hover {
  transition: all 0.2s ease-in-out;
  transform: scale(1.01);
  -webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.15);
}
.icon {
  background: $white-color;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.footer {
  display: flex;
  position: absolute;
  bottom: 16px;
  right: 16px;
}
.save {
  font-family: sans-serif;
  border-radius: 5px;
  cursor: pointer;
  padding: 7px 25px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  width: 87px;
  color: $white-color;
  background-color: #002d71;
  border: 1px solid #002d71;
  margin-left: 12px;
}

.disabled {
  background-color: $grey-color !important;
  border: 0;
  cursor: not-allowed;
}
