@import '../../../../../assets/styles/partials/mixins.scss';

.whiteInput {
  background: #fff;
}

.howFessWillBePaidSection {
  > div.details {
    display: flex;
    margin-bottom: 12px;
    @include screen(xs) {
      flex-direction: column;
      align-items: center;
      background-color: #fff;
      padding: 24px;
    }

    > div {
      min-width: 178px;
      width: auto;
      @include screen(xs) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    > div p {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
    }

    span {
      font-weight: bold;
      color: #000;
    }
    > hr {
      margin: 12px 24px;
    }
  }
}
