@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';

.modalContainer {
  min-width: 48vw;

  @include screen(xs) {
    width: 80vw;
    min-width: 80vw;
  }

  .container {
    color: $text-color;
    text-align: center;

    h1 {
      font-size: $font-size-hg;
      margin: 0px;
      color: $secondary-color;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 20px;
    }

    a {
      margin-left: 4px;
    }

    > img {
      height: 60px;
    }
  }
}
