@import '../../assets/styles/partials/variables.scss';

.radio {
  display: flex;
  margin-right: 10px;
  align-items: center;
  cursor: pointer;

  p {
    margin: 0px 5px;
    padding-left: 10px;
    color: $text-color;
  }

  > .inputGroup {
    display: inline-flex;
    flex-direction: row;

    .labelRadio {
      display: flex;
      padding: 0px;
      margin: 0px;
      left: -20px;
      position: relative;
      z-index: 2;

      &:after {
        width: 18px;
        height: 18px;
        content: '';
        border: 2px solid $grey-color;
        background-color: $white-color;
        border-radius: 50%;
        z-index: 2;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: all 200ms ease-in;
        cursor: pointer;
      }
    }

    input:checked ~ label {
      color: $white-color;

      &:before {
        transform: translate(-50%, -50%) scale3d(56, 56, 1);
        opacity: 1;
      }

      &:after {
        background-color: $primary-color;
        border-color: $primary-color;
        box-shadow: 0px 0px 0 2px $primary-color;
        padding: 0px;
        border: 3px solid white;
      }
    }

    input {
      visibility: hidden;
    }
  }
}
