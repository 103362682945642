@import '../../../assets/styles/partials/variables.scss';
@import '../../../assets/styles/partials/mixins.scss';

.container {
  width: 100%;
  background: #fff;

  @include screen(max-sm) {
    grid-template-columns: 1fr;
  }
}

.addButton {
  background: #f7f7f7;
  @include screen(max-sm) {
    width: 100%;
  }
}
