@import '../../../assets/styles/partials/variables.scss';
@import '../../../assets/styles/partials/mixins.scss';

.container {
  display: flex;
  flex-direction: column;
  background: $white-color;
  @include screen(max-sm) {
    padding: 0 10%;
  }

  h1 {
    font-size: $font-size-lg;
    color: $title-color;
    align-self: center;
  }

  h2 {
    font-size: $font-size;
  }

  h3 {
    font-size: $font-size;
    font-weight: normal;
    width: 100%;
  }

  .personType {
    border-bottom: 1px solid rgba(160, 160, 160, 0.5);
    padding-bottom: 25px;
  }

  .fieldGroup {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.contact {
  justify-content: inherit;
  > div {
    &:last-child {
      margin-left: 1.5rem;
      @include screen(max-sm) {
        margin-left: 0;
      }
    }
  }
}
.address {
  > fieldset {
    width: 100%;
    div {
      margin: 5px 0 12px;
    }
  }
}
