@import '../../../../assets/styles/partials/variables.scss';
@import '../../../../assets/styles/partials/mixins.scss';

.container {
  max-width: 854px;
  min-height: 617px;
  width: 100%;
  background-color: $background-color;
  font-family: $font-family-open-sans;

  display: flex;
  flex-direction: column;
  border-radius: 4px;

  @include screen(max-sm) {
    align-items: center;
    margin-top: 26px;
  }
}

.title {
  display: flex;
  align-items: center;
  margin-left: 24px;
  margin-top: 24px;
  font-size: $font-size-lg;
  color: $title-color;

  h1 {
    margin-block-start: 0em;
    margin-block-end: 0em;
  }

  img {
    width: 100%;
    height: 100%;
    margin-right: 10px;

    max-width: 45px;
    max-height: 45px;
  }
}

.description {
  text-align: left;
  margin-left: 24px;
}

.proponentsContainer {
  display: flex;
  flex-wrap: wrap;
  max-width: 880px;
  margin-top: 24px;

  > div,
  > button {
    margin-left: 24px;
    margin-bottom: 24px;
  }
}
