@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';
%base-button {
  font-family: sans-serif;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  padding: 10px 35px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
}

%base-button:hover {
  transition: all 0.2s ease-in-out;
  transform: scale(1.05);
  -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.15);
}

.primary {
  @extend %base-button;
  color: $white-color;
  background-color: #002d71;
  border: 1px solid #002d71;
}

.outline {
  @extend %base-button;
  color: #002d71;
  background-color: $white-color;
  border: 1px solid #8b95a1;
  @include screen(max-sm) {
    padding: 10px 14px;
    min-width: 148px;
  }
}

.label {
  display: flex;
  justify-content: center;
}

.disabled {
  @extend %base-button;
  background-color: $grey-color !important;
  border: 0;
  cursor: not-allowed;
}

.underlined {
  display: flex;
  align-items: center;
  border: 0;
  background-color: transparent;
  text-decoration: underline;
  cursor: pointer;
}
