@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';

.saleValueText {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .text {
    font-size: 1em;
    word-break: normal;
  }

  .propertyCard {
    @include screen(min-lg) {
      display: none;
      font-size: $font-size;
    }
  }
}
