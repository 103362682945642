@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.main {
  display: flex;
  width: 100%;
  min-height: 100%;
  min-width: 0;
  max-width: 1280px;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 5rem;
  justify-content: space-between;

  @include screen(max-sm) {
    flex-direction: column-reverse;
    align-items: center;
  }

  .card {
    width: 100%;
    max-width: 368px;
  }
}
.stepBar {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
