.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 855px;

  .selectContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;

    svg {
      display: none;
    }
  }
}
