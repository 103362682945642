@import '../../assets/styles/partials/mixins.scss';
@import '../../assets/styles/partials/variables.scss';

$small-screen: 768px;

.card {
  display: inline-flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: $white-color;
  margin-bottom: 12px;

  min-width: 300px;
  max-width: 380px;
  height: 494px;

  a {
    text-decoration: none;
    position: relative;
  }

  .image {
    overflow: hidden;
    margin: 0;
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 220px;
    max-width: 100%;
    object-fit: cover;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    img {
      object-fit: cover;
    }
  }

  .image::after {
    -moz-box-shadow: inset 0 39px 35px 0px #0000147d;
    -webkit-box-shadow: inset 0 39px 35px 0px #0000147d;
    box-shadow: inset 0 39px 35px 0px #0000147d;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 98%;
    z-index: 0;
    content: '';
  }

  > .information {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 20px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    margin-top: 6px;
    width: auto;
    height: auto;

    p {
      color: $text-color;
      font-size: $font-size;
      font-size: 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding: 0 16px 0 0;
    }

    p + p {
      padding-top: 10px;
    }

    h1 {
      font-size: $font-size-med;
      font-weight: 600;
      color: $black-light;
      margin-block-start: 0px;
      margin-block-end: 0;
    }
  }
  .tags {
    padding: 16px 20px 8px 20px;
    margin-bottom: 2px;
    flex-wrap: wrap;
    display: flex;

    .scrollContainer {
      cursor: pointer;
      display: flex;
      align-items: flex-start;
    }
  }

  .features {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-content: center;
    height: 32px;
    margin-top: 10px;

    .feat {
      display: flex;
      flex-direction: row;
      span {
        :first-child {
          margin-right: 7px;
        }
      }
    }

    p {
      color: $text-color;
      font-size: $font-size;
    }

    .dot {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: $sea-green;
      margin: 0 8px;
    }

    .feat:last-child .dot {
      visibility: hidden;
    }
  }

  > .values {
    display: flex;
    padding: 15px;
    border-left: 1px solid $border-grey;
    width: 90%;
    align-self: center;
    flex-direction: column;
    justify-content: center;
    padding-top: 0px;
    padding-bottom: 0px;
    border: none;

    > .prices {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      align-items: center;
      margin-bottom: 10px;

      div {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;

        p {
          font-size: 16px;
        }
      }

      > .discount {
        display: flex;
        flex-direction: column;
        width: 100%;

        .percent {
          font-size: 18px;
        }
      }
    }
  }
}

.ellipsis {
  font-size: $font-size-small;
  color: $dark-grey-color;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: initial;

  @include screen(max-md) {
    margin-top: 0.5em;
  }
}

.link {
  text-decoration: none;
  color: $dark-blue-color;
  font-weight: bold;
  font-size: $font-size-med;
  text-align: left;
}

.desagio {
  display: flex !important;
  justify-content: space-around !important;
  align-items: center !important;
  border-radius: 5px;
  width: 50px !important;
  padding: 0 5px !important;
  position: absolute !important;
  left: 4% !important;
  bottom: 4% !important;
  background-color: $red-blue-color;
  color: white;
  font-size: 16px;
  font-weight: bold;
  transform: unset !important;
}

.novidade {
  background-color: #fffffe;
  border-radius: 13px;
  padding: 4px 12px;
  font-size: 14px;
  line-height: 16px;
  color: #262626;
  position: absolute;
  top: 18px;
  left: 18px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    margin-left: 4px;
  }
  img {
    width: 14px;
    height: 14px;
  }
}

.bankLogo {
  position: absolute;
  top: 4%;
  right: 4%;
  max-width: 160px;
  max-height: 60px;
  z-index: 3;

  &.propertyIsSold {
    right: unset;
    left: 4%;
  }
}

.sold {
  & .soldTag {
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: absolute;
    display: flex;
    top: 13%;
    right: -28%;
    padding: 5px 0;
    text-align: center;
    transform: rotate(40deg);
    height: 25px;
    z-index: 1;
    align-self: baseline;

    span {
      background-color: $red-blue-color;
      color: $white-color;
      width: 93%;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.9px;
    }

    @include screen(xs) {
      top: 10%;
      width: 80%;
    }

    @include screen(max-sm) {
      width: 100%;
    }

    @include screen(min-md) {
      width: 80%;
    }

    @include screen(max-md) {
      width: 94%;
    }

    @include screen(min-lg) {
      width: 100%;
    }
  }
}

.tooltip {
  background-color: $white-color !important;
  color: $black-color !important;
  font-weight: bold;
  width: 300px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
}
