@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';

.modalContainer {
  @include screen(xs) {
    width: 80vw;
  }

  .container {
    > header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .title {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        color: $title-color;
      }
    }

    .text {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      margin-bottom: 36px;
    }

    > footer {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 12px;

      .btnTutorial,
      .btnContinue {
        width: 206px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
