@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';

.container {
  border-radius: 4px;
  padding: 24px;
  width: 100%;
  box-sizing: border-box;

  & > header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  & > header p {
    font-size: 16px;
    font-weight: 600;
    color: $black-color;
  }
}
.card {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.title {
  display: flex;
  justify-content: space-between;

  width: 100%;
}

.cardContent {
  flex-wrap: wrap;
  margin-right: 44px;
  margin-bottom: 20px;
}

.uploadFile,
.uploadFileEnd {
  width: 290px;
  height: 256px;
  background: #fff;
  padding: 16px;
  border: 2px dashed #b5b5b5;
  border-radius: 4px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  input {
    display: none;
  }
}
.uploadFileEnd {
  position: relative;
  background: #ececec;
  border: none;
  overflow: hidden;
  & > img {
    max-width: 100%;
  }
}
.documentActions {
  border: red;
  display: flex;
  position: absolute;
  width: 45%;
  justify-content: space-between;
  & > div {
    width: 64px;
    height: 64px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.documentStatus {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
  border: 1px solid #ececec;
  border-radius: 0 0 4px 4px;
  background: #ffffff;
  width: 100%;
  padding: 8px 0;
  & > svg {
    margin: 0 6px 0 10px;
  }
}

.files {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 12px;

  p {
    display: block;
    text-align: start;
    margin-bottom: 4px;
  }
}

.fileHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    cursor: pointer;
  }
}

.progressBar {
  flex-direction: column-reverse;
}
