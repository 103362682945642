@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';

.card {
  width: 260px;
  height: auto;
  max-height: 260px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border: 1px solid #ececec;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;

  @include screen(xs) {
    width: 100%;
  }

  > h1 {
    all: unset;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #1a1a1a;
  }

  .options {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
  }

  .trash {
    width: 32px;
    height: 32px;
  }

  .edit {
    width: 144px;
    height: 40px;
  }

  .financialApplication {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    h1 {
      all: unset;
      display: flex;
      gap: 8px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 19px;
      color: #8a8a8a;
    }

    p {
      color: #1a1a1a;
    }
  }
}
