@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';

.discount {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .percent {
    color: $sea-green;
    font-weight: 700;
    margin: 0;
    margin-left: 20px;
  }
  .percent.medium {
    font-size: $font-size-med;
  }

  .percent.large {
    font-size: $font-size-hg;
  }

  span {
    display: inline-flex;
    align-items: center;
  }
}
