@import '../../assets/styles/partials/variables.scss';

.container {
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 36px;

  header {
    margin-bottom: 16px;

    span {
      margin-left: 13px !important;
    }
  }
}
.uploads {
  width: 256px;
  height: 256px;
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  input {
    display: none;
  }

  span {
    text-align: center;
  }
}
.uploadFile {
  background: $white-color;
  border: 1px dashed #b5b5b5;
}

.uploadedFile {
  background: $light-grey;
}

.thumbsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  img {
    min-width: 152px;
    min-height: 230px;
    max-height: 250px;
    max-width: 250px;
  }
}

.thumb {
  display: inline-flex;
  border-radius: 2;
  border: 1px solid #eaeaea;
  margin-bottom: 8;
  margin-right: 8;
  width: 100;
  height: 100;
  padding: 4;
  box-sizing: border-box;
}

.thumbInner {
  display: flex;
  min-width: 0;
  overflow: hidden;
}

.img {
  display: block;
  width: auto;
  height: 100%;
}

.greyImage {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray; /* IE */
}

.actions {
  position: absolute;
  top: 35%;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  margin-left: 0 !important;
}
