$primary-color: #00bfb2;
$secondary-color: #002d72;
$background-color: #f5f5f5;
$white-color: #ffffff;
$header-color: #ececec;

$title-color: #002d72;
$title-color-hover: #2662bd;
$light-grey: #ececec;
$grey-text: #a0a0a0;
$text-color: #444444;
$dark-color: #4a4a4a;
$grey-color: #9d9d9d;
$dark-grey-color: #444444;
$black-color: #1a1a1a;
$black-light: #262626;
$light-dark-color: #f7f7f7;
$brow-grey: #8a8a8a;

$green: #1ac88d;
$sea-green: #00bfb2;

$line-color: #dadade;

$aceppted: #28cb93;
$analisys: #f2cf01;
$reject: #ff7609;
$error: #ff4040;
$border-grey: #e0e0e0;
$dark-blue-color: #23355a;
$red-blue-color: #fb4e5f;

$font-size-xsmall: 12px;
$font-size-small: 14px;
$font-size: 16px;
$font-size-med: 18px;
$font-size-lg: 20px;
$font-size-hg: 24px;

$heading-1: 2.488rem;
$heading-2: 2.074rem;
$heading-3: 1.728rem;
$heading-4: 1.44rem;
$heading-5: 1.2rem;
$heading-6: 1rem;

$padding-global-left: 55px;
$padding-global-right: 45px;
$font-family: 'Titillium Web', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
  'Segoe UI Symbol';
$font-family-open-sans: 'Open Sans';

:export {
  error: $error;
  success: $aceppted;
  primary-color: $primary-color;
  dark-grey-color: $dark-grey-color;
}
