@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';

.banner {
  top: 0px;
  z-index: 0;
  @include screen(max-lg) {
    display: flex;
    flex-direction: row-reverse;
  }
}
.bannerImage {
  width: 100%;

  @include screen(xs) {
    display: none;
  }

  @include screen(max-lg) {
    width: auto;
  }
}

.bannerImageMobile {
  width: 100%;

  @include screen(min-sm) {
    display: none;
  }
}
