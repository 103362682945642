@import '../../assets/styles/partials/mixins.scss';

.container {
  display: flex;
  padding: 108px;

  @include screen(max-sm) {
    padding: 0;
    .property {
      display: none;
    }
  }

  @include screen(max-lg) {
    flex-direction: column;
    align-items: center;
    .property {
      margin-top: 30px;
    }
  }
}
