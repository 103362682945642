@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';

.titleProfileData {
  margin: 0 0 12px 0;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.18px;
}

.container {
  background-color: $light-dark-color;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  border-radius: 12px;
  box-sizing: border-box;

  div[class*='inputElement'] {
    border-color: transparent;
    border-bottom-color: $header-color;
    transition: all 0.4s;

    &.focus {
      border-bottom-color: $text-color;
      transition: all 0.4s;
    }

    &[class*='invalid'] {
      border-color: transparent;
      border-bottom-color: $header-color;
    }
  }

  .editInput {
    display: flex;
    position: relative;

    div[class*='inputContainer'] {
      width: 100%;
      position: relative !important;

      label {
        width: 100%;
      }
    }

    .icon {
      all: unset;
      display: flex;
      align-items: center;
      gap: 3px;
      position: absolute;
      right: 5%;
      bottom: 17%;
      cursor: pointer;
    }
  }
}

@media (max-width: 570px) {
  .container {
    padding: unset;

    .editInput {
      .icon {
        margin-right: 8px;
      }
    }
  }
}
