@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';

.container {
  color: $text-color;
  text-align: center;

  h1 {
    font-size: $font-size-hg;
    margin: 0px;
    color: $secondary-color;
  }

  p {
    margin-bottom: 20px;
  }

  > img {
    width: 60px;
    background: $light-grey;
    border-radius: 100%;
  }
}

.questionsWrapper {
  max-height: 400px;
  overflow-y: auto;
  font-weight: 600;

  & > * {
    width: 100%;
  }
}

.question {
  background: $light-grey;
  display: block;
  box-sizing: border-box;
  color: $text-color;
  padding: 12px;
  text-decoration: none;
  text-align: left;
  margin-bottom: 10px;
  font-size: $font-size-med;
  border-radius: 5px;
}

.footer {
  border-top: 1px solid adjust-color($grey-text, $alpha: -0.5);
  margin-top: 10px;
  padding-top: 10px;

  p {
    line-height: 1.3;
    margin-bottom: 14px;
  }
}

.buttons {
  display: flex;
  width: 100%;

  @include screen(max-sm) {
    flex-direction: column;
  }

  & > * {
    flex: 1;
    padding: 14px;
    background: $light-grey;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    place-items: center;
    text-align: left;

    p {
      margin: 0;
      margin-left: 8px;
    }
  }

  & > *:first-child {
    margin-right: 15px;

    @include screen(max-sm) {
      margin-right: 0;
      margin-bottom: 8px;
    }
  }
}
