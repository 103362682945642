@import '../../../../assets/styles/partials/variables.scss';
@import '../../../../assets/styles/partials/mixins.scss';

.container {
  max-width: 878px;
  min-height: 617px;
  width: 100%;
  background: #fff;
  color: $brow-grey;

  display: flex;
  flex-direction: column;

  & > div {
    border-radius: 4px;
    padding: 24px;
    background-color: $background-color;
    margin-bottom: 12px;
  }

  h2 {
    font-size: 16px;
    margin-bottom: 16px;
    margin-top: 36px;
    color: #000;
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.title {
  display: flex;
  align-items: center;
  font-size: $font-size;
  color: $title-color;
  padding: 0;

  h1 {
    margin-block-start: 0em;
    margin-block-end: 0em;
  }

  img {
    width: 100%;
    height: 100%;
    margin-right: 10px;

    max-width: 45px;
    max-height: 45px;
  }
}

.originResourcesContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  @include screen(xs) {
    flex-direction: column;
  }
}
.originResourcesSection {
  h2 {
    margin-top: 0;
  }
}
