@import '../../../../../assets/styles/partials/mixins.scss';

.whiteInput {
  background: #fff;
}

.howPropertyWillBePaidSection {
  > div:nth-child(2) {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-bottom: 12px;

    @include screen(xs) {
      flex-direction: column;
    }
    span {
      color: #000;
      font-weight: bold;
      margin: 30px 12px 0;
    }
  }
}
