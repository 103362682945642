@import '../../assets/styles/partials/variables.scss';

.container {
  font-style: normal;
  color: $text-color;
  padding: 12px;
  width: 290px;
  background: hsl(0, 0%, 100%);
  position: absolute;
  bottom: 0;
  border: 1px solid #ececec;
  box-sizing: border-box;
  border-radius: 0 0 8px 8px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      display: flex;
      svg {
        margin-right: 4px;
      }
    }

    .title {
      margin: 0;
      font-weight: bold;
      font-size: $font-size-small;
      line-height: 20px;
    }
  }

  p {
    font-weight: normal;
    font-size: $font-size-small;
    line-height: 19px;
    margin: 4px 17px !important;
    text-align: start !important;
  }
}
