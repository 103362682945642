@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';

.container {
  color: $text-color;
  text-align: center;

  h1 {
    font-size: $font-size-hg;
    margin: 0px;
    color: $secondary-color;
  }

  p {
    margin-bottom: 20px;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button + button {
    margin-top: 8px;
  }
}
