@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';

.container {
  box-sizing: border-box;
  min-height: 60vh;
  h1 {
    margin: 0 8px 0 0;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: $title-color;
  }

  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: start;
  }

  h6 {
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    margin-top: 16px;
    margin-left: 14px;
    text-align: start;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
  }

  .containerGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    overflow: hidden;

    @include screen(xs) {
      grid-template-columns: 1fr;
    }

    > article {
      display: flex;
      flex-direction: column;

      > div {
        flex: 1;
      }
    }

    .content {
      background-color: $background-color;
      padding: 18px;
      height: 100%;
    }
  }

  .complementaryDocs {
    margin-top: 24px;
    h2 {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
    }

    .contentBottom {
      background-color: $background-color;
      padding: 18px;
    }
  }
}

.listDocs {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 17px;

  .doc {
    display: flex;
    align-items: center;
    gap: 14px;

    .ball,
    .ballYellow,
    .ballOrange {
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }

    .ball {
      background-color: #d8d8d8;
    }

    .ballYellow {
      background-color: #f2cf01;
    }

    .ballOrange {
      background-color: #ff7609;
    }
  }

  li {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 4px;
  }

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
  }
}

.btnContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}
