@import '../../assets/styles/partials/variables.scss';

.text {
  margin: 0px;
  white-space: normal;
  word-break: break-word;
}

.primaryColor {
  color: $text-color;
}

.darkBlueColor {
  color: $secondary-color;
}

.whiteColor {
  color: $white-color;
}

.lightBlueColor {
  color: $title-color;
}

.blackColor {
  color: $dark-grey-color;
}

.resaleGreenColor {
  color: $sea-green;
}

.textColor {
  color: $text-color;
}

.xxsmall {
  font-size: $font-size-xsmall;
}

.xsmall {
  font-size: $font-size-xsmall;
}

.greyColor {
  color: $light-grey;
}

.small {
  font-size: $font-size-small;
}

.medium {
  font-size: $font-size;
}

.regular {
  font-size: $font-size-med;
}

.large {
  font-size: $font-size-lg;
}

.xlarge {
  font-size: $font-size-hg;
}

.bold {
  font-weight: 600;
}
