$text-color: #444444;
$text-color-light: #8a8a8a;

main {
  display: flex;
  justify-content: center;
  height: auto;
  width: 100%;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;

    .item {
      display: flex;
      align-items: flex-start;
      gap: 16px;

      .date,
      .title {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
      }

      .date {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        color: $text-color-light;
      }

      .title {
        flex: 1;
        display: flex;
        flex-direction: column;
        color: $text-color;

        &.bold {
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
          color: $text-color;
        }

        &.initial {
          color: $text-color-light;
        }

        .option {
          all: unset;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 20px;
          text-decoration-line: underline;
          color: $text-color;
          cursor: pointer;
        }
      }

      &:last-child {
        .separator {
          display: none;
        }
      }
    }

    .timeline {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .timelineItem {
        display: flex;
        justify-content: center;
        align-self: center;
        min-width: 30px;
      }

      .icon {
        border-radius: 50%;
        background-color: white;
        border: 2px solid hsla(0, 0%, 85%, 1);
        width: 16px;
        height: 16px;

        &.accepted {
          background-color: #00bfb2;
          border-color: #00bfb2;
        }

        &.disapproved {
          width: 28px;
          height: 28px;
          background-color: #ff4040;
          border-color: #ff4040;
          background-image: url('../../assets/svg/interrogation.svg');
          background-repeat: no-repeat;
          background-position: center center;
        }
      }

      .separator {
        width: 2px;
        height: 36px;
        background-color: #dadada;
        align-self: center;

        &.accepted {
          background-color: #00bfb2;
        }
      }
    }
  }
}
