@import '../../../../assets/styles/partials/variables.scss';
@import '../../../../assets/styles/partials/mixins.scss';

.documentationContainer {
  max-width: 725px;
  min-height: 543px;
  width: 100%;
  background: $background-color;
  color: $text-color;
  padding: 36px 90px;
  border-radius: 12px;
  margin-right: 24px;

  @include screen(max-sm) {
    width: 100%;
    padding: 36px 0;
    margin-right: 0;
    border-radius: 0;
  }

  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: $font-size-lg;
    line-height: 27px;
    text-align: center;
    color: $title-color;
    margin: 0;
  }

  p {
    margin: 24px 0;
    text-align: center;
    padding: 0 8px;
  }

  .buttons {
    display: flex;
    justify-content: center;
    button {
      width: 206px;
      height: 40px;
    }
    @include screen(max-sm) {
      justify-content: center;
      flex-direction: column;
      align-items: center;
      button {
        margin-bottom: 6px;
        margin-top: 6px;
      }
    }

    @include screen(min-sm) {
      :first-child {
        margin-right: 6px;
      }
      :last-child {
        margin-left: 6px;
      }
    }
  }

  .cards {
    display: flex;
    @include screen(max-sm) {
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
    :first-child {
      margin-right: 6px;
    }
    :last-child {
      margin-left: 6px;
    }
  }
}
