@import '../../assets/styles/partials/variables.scss';
@import '../../assets/styles/partials/mixins.scss';

.container {
  display: flex;
  align-items: flex-start;
  gap: 35px;
  width: 100%;
  padding: 36px 40px 36px 80px;

  @include screen(xs) {
    padding: 36px 0 36px 0;
  }

  .left {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1;
  }

  section {
    > h1 {
      margin: 0 0 12px 0;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.18px;
      color: $text-color;
    }
  }

  @include screen(xs) {
    flex-direction: column;
    align-items: center;

    section {
      padding-right: unset;
    }
  }
}

.content {
  width: 75%;
  > header {
    margin: 30px;
    font-weight: 600;
    color: #000;
    font-size: 14px;
  }
}

@media (max-width: 825px) {
  .container {
    display: flex;
    flex-direction: column;
  }
}
