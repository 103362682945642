@import '../../assets/styles/partials/variables.scss';

.primaryColor {
  fill: $primary-color;
}

.blackLight {
  fill: $black-light;
}

.darkColor {
  fill: $secondary-color;
}

.greyColor {
  fill: $grey-color;
}

.whiteColor {
  fill: $white-color;
}

.greenColor {
  fill: $sea-green;
}

.rotate {
  transform: rotate(135deg);
}

svg {
  cursor: pointer;
}
