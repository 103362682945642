@import '../../assets/styles/partials/mixins.scss';
@import '../../assets/styles/partials/variables.scss';

.progress-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 0.5;

  span {
    font-size: $font-size-xsmall;
    color: $text-color;
    padding-bottom: 4px;
  }

  .progress-bar {
    background: $line-color;
    width: 100%;
    height: 8px;
    border-radius: 4px;

    .progress {
      height: inherit;
      border-radius: inherit;
      background: $green;
    }
  }
}
